<template>
  <div>
    <v-card class="bordered ma-0 pa-0" flat outlined height="110">
      <div class="d-flex justify-space-between card-content">
        <div class="d-flex">
          <div>
            <v-fade-transition>
              <v-img
                class="global-img"
                :src="Item[logoName]"
                @error="handleLogoError($event, Item)"
                width="80"
                max-height="60"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-fade-transition>
            <div class="text-white badge primary-bg text-center w-80 mt-1">
              {{ Item?.objeto_vigencia?.vigencia_descripcion ?? "" }}
            </div>
          </div>
          <div class="d-flex flex-column my-2">
            <span class="bold title-color">{{
              Item?.nombre_producto ?? ""
            }}</span>
            <span class="bold title-color">
              {{ Item?.marca_descripcion ?? "" }}
              {{ Item?.modelo_descripcion ?? "" }}
              {{ Item?.anio ?? "" }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-column my-2">
          <h6 class="regular primary--text" v-if="alineacion === 'derecha'">
            DEDUCIBLE
            {{ Item?.deducible ?? "" }}
            {{ Item?.moneda_deducible?.simbolo }}
          </h6>
          <h6 class="regular primary--text" v-else>
            DEDUCIBLE
            {{ Item?.moneda_deducible?.simbolo }}
            {{ Item?.deducible ?? "" }}
          </h6>
          <span>
            Monto mensual -
            <span class="font-weight-bold">{{ cuotas }}</span> cuotas de</span
          >
          <span class="bold title-color">
            {{ Item?.moneda_local?.simbolo ?? "$"
            }}{{ (Item?.moneda_local?.precio ?? "0") | numberFormat }} |
            <span class="primary--text bold">
              {{ Item?.moneda_seguro?.simbolo ?? "" }}
              {{ (Item?.moneda_seguro?.precio ?? "0") | numberFormat }}
            </span>
          </span>
        </div>
        <div class="d-flex flex-column my-2 align-start">
          <v-btn text small @click="$emit('ver-mas', Item)" class="text-none">
            <v-icon>mdi-eye</v-icon>
            <span class="ml-2 bold primary--text">Ver más</span>
          </v-btn>
          <v-btn
            text
            small
            :loading="deleteloader"
            @click="deleteItemCart(Item)"
            :disabled="principal"
            class="text-none"
            ><v-icon>mdi-trash-can</v-icon>
            <span class="ml-2 bold primary--text">Eliminar</span>
          </v-btn>
          <v-btn
            text
            small
            :loading="deleteloader"
            :disabled="!descuento"
            @click="toggleModal(1)"
            class="text-none"
            ><v-icon>mdi-sale</v-icon>
            <span class="ml-2 bold primary--text">Solicitar descuento</span>
          </v-btn>
        </div>
        <ModalRequestDiscount
          v-if="openModal"
          @close="toggleModal(1)"
          @handleSubmit="handleSubmit"
          :data="Propuesta"
        />
        <ModalPendingDiscount
          v-if="openPendingModal"
          @close="toggleModal(2)"
          @handleSubmit="handleSubmit"
          :data="Propuesta"
          :descuento="descuentoData"
          :estado="descuentoData.res.estado"
        />
        <ModalApprovedDiscount
          v-if="openApprovedModal"
          @close="toggleModal(3)"
          @handleSubmit="handleSubmit"
          :data="Propuesta"
          :descuento="descuentoData"
        />
        <ModalRejectedDiscount
          v-if="openRejectedModal"
          @close="toggleModal(4)"
          @handleSubmit="handleSubmit"
          :data="Propuesta"
          :descuento="descuentoData"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import { cartItem } from "@/Mixins/CartComponentMixin.js";

import ModalRequestDiscount from "@/components/Modal/Descuentos/Modal-discount-request.vue";
import ModalApprovedDiscount from "@/components/Modal/Descuentos/Modal-approved-discount.vue";
import ModalRejectedDiscount from "@/components/Modal/Descuentos/Modal-rejected-discount.vue";
import ModalPendingDiscount from "@/components/Modal/Descuentos/Modal-pending-discount.vue";
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
export default {
  name: "ArticuloDelCarrito",
  mixins: [cartItem, LogoRequestMixin],
  props: {
    Item: {
      type: Object,
      default: null,
    },
    Propuesta: {
      type: Object,
      default: () => {},
    },
    VerMas: {
      type: Function,
      default(itms) {
        return itms;
      },
    },
    cuotas: {
      type: Number,
      default: 0,
      description: "Numero de cuotas a mostrar",
    },
    principal: {
      type: Boolean,
      default: true,
    },
    descuento: {
      type: Boolean,
      default: false,
      description: "Check si tiene descuento o no el canal",
    },
    alineacion: {
      type: String,
      default: "izquierda",
    },
  },
  components: {
    ModalRequestDiscount,
    ModalApprovedDiscount,
    ModalRejectedDiscount,
    ModalPendingDiscount,
  },
  data() {
    return {
      deleteloader: null,
      id: this.$route.params.id,
      openModal: false,
      openApprovedModal: false,
      openRejectedModal: false,
      openPendingModal: false,
      descuentoData: {},
    };
  },
  methods: {
    deleteItemCart(Item) {
      this.deleteItem(Item);
    },
    toggleModal(val) {
      switch (val) {
        case 1:
          this.openModal = !this.openModal;
          break;
        case 2:
          this.openPendingModal = !this.openPendingModal;
          break;
        case 3:
          this.openApprovedModal = !this.openApprovedModal;
          break;
        case 4:
          this.openRejectedModal = !this.openRejectedModal;
          break;
      }
    },
    handleSubmit(data, val) {
      switch (val) {
        case 1:
          this.descuentoData = data;
          this.openModal = false;
          this.openPendingModal = true;
          break;
        case 2:
          this.descuentoData = data;
          this.openPendingModal = false;
          this.$emit("refresh");
          this.openApprovedModal = true;
          break;
        case 3:
          this.descuentoData = data;
          this.openPendingModal = false;
          this.openRejectedModal = true;
          break;
      }
    },
  },
};
</script>

<style scoped>
.badge {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
