<template>
  <div>
    <v-card class="light" flat>
      <v-card-title class="pt-0">
        <h5>Resumen de compra</h5>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column">
          <div
            class="d-flex justify-space-between"
            v-for="(item, i) in Cart"
            :key="i"
          >
            <span
              class="regular text-capitalize"
              v-if="item?.moneda_deducible?.alineacion === 'derecha'"
            >
              DEDUCIBLE
              {{ item?.deducible }}
              {{ item?.moneda_deducible?.simbolo }}
            </span>
            <span class="regular text-capitalize" v-else>
              DEDUCIBLE
              {{ item?.moneda_deducible?.simbolo }}
              {{ item?.deducible }}
            </span>
            <span>
              {{ item?.moneda_local?.simbolo ?? "$"
              }}{{ (item?.moneda_local?.precio ?? "0") | numberFormat }} |
              {{ item?.moneda_seguro?.simbolo ?? "UF" }}
              {{ item?.moneda_seguro?.precio ?? "0" | numberFormat }}</span
            >
          </div>
          <!-- DESCUENTO -->
          <div class="d-flex justify-space-between" v-if="descuento">
            <span class="regular text-capitalize">
              Descuento
              {{ descuento?.monto_descuento }}%
            </span>
          </div>

          <div class="d-flex justify-space-between mt-4">
            <span class="bold">Total</span>
            <span>
              <span class="bold title-color"
                >{{ TotalPrice.localSymbol
                }}{{ TotalPrice.localTotal | numberFormat }}</span
              >
              |
              <span class="primary--text bold"
                >{{ TotalPrice.seguroSymbol }}
                {{ TotalPrice.seguroTotal | numberFormat }}</span
              >
            </span>
          </div>

          <div class="d-flex justify-center mt-8">
            <v-btn
              color="primary"
              rounded
              :loading="loading"
              @click="$emit('purchase')"
              class="text-none px-10"
              name="boton_continuar"
              >Continuar contratación</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    Cart: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    descuento: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    TotalPrice() {
      let localTotal = 0;
      let seguroTotal = 0;
      const primerObjeto = this.Cart[0];
      var total = {};
      if (this.descuento) {
        total = {
          seguroTotal: this.descuento?.nuevo_monto_seguro,
          localTotal: this.descuento?.nuevo_monto_seguro_local,
          seguroSymbol: primerObjeto?.moneda_seguro?.simbolo ?? "UF",
          localSymbol: primerObjeto?.moneda_local?.simbolo ?? "$",
        };
      } else {
        this.Cart.forEach((element) => {
          localTotal += element.moneda_local?.precio ?? 0;
          seguroTotal += element.moneda_seguro?.precio ?? 0;
        });
        total = {
          seguroTotal,
          localTotal,
          seguroSymbol: primerObjeto?.moneda_seguro?.simbolo ?? "UF",
          localSymbol: primerObjeto?.moneda_local?.simbolo ?? "$",
        };
      }
      return total;
    },
  },
};
</script>
