import axios from "axios";
export const cartItem = {
  methods: {
    deleteItem(Items) {
      this.deleteloader = true;

      const form = {
        item: "",
        plan: Items.plan,
      };

      if (Items.plan_descripcion.charAt(0) === "a") {
        form.item = "adicional";
      } else {
        form.item = "complementario";
      }

      axios({
        url: `carrito/${this.propuesta}`,
        method: "DELETE",
        data: form,
      }).finally(() => {
        this.deleteloader = false;
      });
    },
  },
};
