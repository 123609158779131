<template>
  <v-dialog persistent v-model="open" width="950" attach="#main-container">
    <v-card class="br-6 light">
      <v-card-text>
        <v-row class="pl-10 pt-10">
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold w-100"
              >Solicitud de descuento aprobada</span
            >
            <v-btn icon @click="$emit('close')" small class="ml-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <div class="d-flex box pa-4 mb-6">
              <v-icon color="success" large>mdi-check-circle</v-icon>
              <div class="ml-4">
                Su solicitud de
                <b> {{ resume?.descuento?.monto_descuento }}% de descuento </b
                >para el plan
                <b>
                  {{ resume?.producto?.descripcion ?? "" }}
                  {{ resume?.monedas?.moneda_seguro?.simbolo }}
                  {{ resume?.monedas?.moneda_seguro?.precio | normalFormat }}
                </b>
                ha sido aprobada exitosamente.
              </div>
            </div>

            <span class="p semiBold title-color">Resumen de la operación</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Cotización N°</span>
                  <span class="p semiBold py-2">Rut</span>
                  <span class="p semiBold py-2">Nombre</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">{{
                    resume?.numero_cotizacion ?? ""
                  }}</span>
                  <span class="p py-2">{{
                    resume?.asegurado?.numero_documento ?? "" | rutFormat
                  }}</span>
                  <span class="p py-2">
                    {{ resume?.asegurado?.nombres ?? "" }}
                    {{ resume?.asegurado?.primer_apellido ?? "" }}
                    {{ resume?.asegurado?.segundo_apellido ?? "" }}
                  </span>
                </div>
              </div>
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Plan</span>
                  <span class="p semiBold py-2">Compañia</span>
                  <span class="p semiBold py-4">Cuota</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">{{
                    resume?.producto?.descripcion ?? ""
                  }}</span>
                  <span class="p py-2"><v-img :src="logo" width="70" /></span>
                  <span class="p py-2">
                    {{ resume?.monedas?.moneda_local?.simbolo }}
                    {{ resume?.monedas?.moneda_local?.precio | normalFormat }}
                    <span class="px-2">|</span>
                    {{ resume?.monedas?.moneda_seguro?.simbolo }}
                    {{ resume?.monedas?.moneda_seguro?.precio | normalFormat }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-100">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Descuento solicitado</span>
                  <span class="p semiBold py-2"
                    >Valor de cuota con descuento incluido</span
                  >
                  <span class="p semiBold py-2">Cargo descuento</span>
                  <span class="p semiBold py-2">Motivo</span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2"
                    >{{ resume?.descuento?.monto_descuento ?? "" }}%</span
                  >
                  <span class="p py-2">
                    {{ resume?.monedas?.moneda_local?.simbolo }}
                    {{
                      resume?.descuento?.nuevo_monto_seguro_local | normalFormat
                    }}
                    <span class="px-2">|</span>
                    {{ resume?.monedas?.moneda_seguro?.simbolo }}
                    {{ resume?.descuento?.nuevo_monto_seguro | normalFormat }}
                  </span>
                  <span class="p py-2">{{
                    resume?.descuento?.cargo ?? ""
                  }}</span>
                  <span class="p py-2">{{
                    resume?.descuento?.motivo ?? ""
                  }}</span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col>
            <div class="d-flex align-center">
              <v-icon>mdi-update</v-icon>

              <span class="ml-4">
                Serás redirigido en <b>{{ timerCount }} segundos</b> al inicio
                de la plataforma para que sigas cotizando
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                rounded
                @click="$emit('close')"
                color="primary"
                width="180"
                class="text-none ml-4"
                >Entendido</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["toggle", "data", "descuento"],
  mixins: [LogoRequestMixin],
  data() {
    return {
      open: true,
      timerCount: 20,
      discount: {},
      dataProp: {},
      logo: "",
      resume: null,
    };
  },
  async mounted() {
    this.logo = await this.getLogo();
    this.resume = await this.getResume(this.data?.numero_cotizacion);
  },
  methods: {
    ...mapActions("Discounts", ["getResume"]),
    async getLogo() {
      const resume = await this.getResume(this.data?.numero_cotizacion).catch(
        (error) => {
          this.snackbar(error);
        }
      );
      return axios
        .get(resume?.compania?.logo)
        .then((res) => {
          return res?.data?.data?.url;
        })
        .catch((error) => {
          this.snackbar(error);
        });
    },
    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: message,
        top: true,
        right: true,
        color: type,
      });
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          1;
          /* setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.open = false; */
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>
<style scoped>
/* @import "../../assets/CSS/ServiceStyle.css"; */

.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.box {
  background-color: #f4f7fc !important;
}
</style>
