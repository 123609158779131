<template>
  <v-dialog persistent v-model="open" width="950" attach="#main-container">
    <v-card class="br-6 light">
      <v-card-text>
        <v-row class="pl-10 pt-10">
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold w-100"
              >Solicitud de descuento en revisión</span
            >
            <v-btn icon @click="$emit('close')" small class="ml-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <div class="d-flex box pa-4 mb-6">
              <v-icon color="warning" large
                >mdi-clock-time-eight-outline</v-icon
              >
              <div class="ml-4">
                Su solicitud de
                <b> {{ discount?.monto_descuento }}% de descuento </b>para el
                plan
                <b>
                  {{ resume?.producto?.descripcion ?? "" }}
                  {{ resume?.monedas?.moneda_seguro?.simbolo }}
                  {{ resume?.monedas?.moneda_seguro?.precio | normalFormat }}
                </b>
                ha sido enviada exitosamente y se encuentra en proceso de
                revisión.
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col>
            <Loading v-if="isLoading" />
          </v-col>
        </v-row>
        <v-row class="px-10"></v-row>
        <v-row></v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
import { mapActions } from "vuex";
import Loading from "@/layouts/loading.vue";
import { TimerMixin } from "@/Mixins/timerMixin.js";

export default {
  props: ["toggle", "data", "descuento", "estado"],
  mixins: [LogoRequestMixin, TimerMixin],
  components: {
    Loading,
  },
  data() {
    return {
      open: true,
      isLoading: false,
      resume: null,
      state: this.estado,
      discount: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.resume = await this.getResume(this.data?.numero_cotizacion).catch(
      (error) => {
        this.snackbar(error);
      }
    );
    this.startTimer(4000, this.refresh);
  },
  methods: {
    ...mapActions("Discounts", ["getResume", "getDiscountById"]),

    async refresh() {
      const payload = {
        id: this.descuento.res.id_descuento,
      };
      await this.getDiscountById(payload)
        .then((response) => {
          const res = response.solicitud;
          this.discount = res;
          this.state = res.estado;
          if (this.state != "PEN") {
            this.state = res.estado;
            this.stopTimer();
            this.isLoading = false;
            if (this.state === "A") {
              this.$emit("handleSubmit", res, 2);
            } else {
              this.$emit("handleSubmit", res, 3);
            }
          }
        })
        .catch((error) => {
          this.snackbar(error);
        });
    },
    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: message,
        top: true,
        right: true,
        color: type,
      });
    },
  },
};
</script>
<style scoped>
/* @import "../../assets/CSS/ServiceStyle.css"; */

.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.box {
  background-color: #fde4c4 !important;
}
</style>
