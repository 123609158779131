export const TimerMixin = {
  data() {
    return {
      timer: null,
    };
  },
  methods: {
    startTimer(interval, callback) {
      this.timer = setInterval(callback, interval);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  beforeDestroy() {
    this.stopTimer();
  },
};

export default TimerMixin;
